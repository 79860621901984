import React from 'react';
import Text from '../../text';

// -----------------------------------------------------------------------------
// COMPONENT:
// -----------------------------------------------------------------------------
const Headline = () => (
  <React.Fragment>
    <Text size="L" color="secondary" className="m0 center">
      The Warm and Fuzzy
      <br />
      Way to Travel
    </Text>
    <Text className="m0 mt1 center">
    A new pet sitting app where pet owners get free, in-home pet sitting in exchange for giving the pet sitter a free place to stay.
    </Text>
  </React.Fragment>
);

export default Headline;
