/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

class SEO extends React.Component {
  constructor (props) {
    super(props);
    this.state = { error: null };
  }

  componentDidMount() {
    const advancedMatching = {  }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
    const options = {
        autoConfig: true, 	// set pixel's autoConfig
        debug: false, 		// enable logs
    };
    ReactPixel.init('544676506113164', advancedMatching, options);
    ReactPixel.pageView();
  }

  render() {
  const { description, lang, meta, keywords, title } = this.props;
  return (
    <StaticQuery
    query={
      graphql`
        query {
          site {
            siteMetadata {
              title
              description
              author
            }
          }
        }
      `}
    render={data => (
      <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${ data.site.siteMetadata.title }`}
      meta={[
        {
          name: `description`,
          content: description || data.site.siteMetadata.description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description || data.site.siteMetadata.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: data.site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description || data.site.siteMetadata.description,
        },
      ]
        .concat(
          keywords.length > 0 ? {
            name: `keywords`,
            content: keywords.join(`, `),
          } : []
        )
        .concat(meta)}
    />
    )}
  />);
}
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;
