import React from 'react';
import ErrorBoundary from '../components/error-boundary';
import Layout from '../components/layout';
import SEO from '../components/seo';
import OwnersMobile from '../mobile-sections/owners';
import OwnersDesktop from '../desktop-sections/owners';

// -----------------------------------------------------------------------------
// CONSTANTS:
// -----------------------------------------------------------------------------
const KEYWORDS = [
  'Pet',
  'Travel',
  'PetDigs',
  'Pet Digs',
  'House keeper',
  'Pet keeper',
  'San Francisco',
  'Holidays',
  'Free stay',
  'pet sitter',
  'pet sitting',
  'house sitting',
  'house sitter',
  'dog sitter',
  'cat sitter',
  'pets',
  'pet care',
  'accommodation',
  'pet lover',
  'pet lovers',
  'unique travel',
  'free accommodation',
  'free pet care',
  'vacation',
];
// -----------------------------------------------------------------------------
// COMPONENT:
// -----------------------------------------------------------------------------
const IndexPage = ({ location }) => (
  <ErrorBoundary>
    <Layout location={location}>
      <SEO title="Pet Digs" keywords={KEYWORDS} />
      <div className="hide-for-large">
        <OwnersMobile />
      </div>
      <div className="show-for-large">
        <OwnersDesktop />
      </div>
    </Layout>
  </ErrorBoundary>
);

export default IndexPage;
