import React from 'react';
import styled from 'styled-components';
import Section from '../../components/section';
import Headline from '../../components/owners/headline';
import Subheadline from '../../components/owners/subheadline';
import Text from '../../components/text';
import OwnerEmily from '../../components/owner-emily';
import OwnerSuzanne from '../../components/owner-suzanne';
import OwnerJulia from '../../components/owner-julia';
import * as HowItWorks from '../../components/owners/how-it-works';
import CTA from '../../components/cta';
import ImageHeaderBG from '../../components/image-header-bg';
import ImageSubheading from '../../components/image-subheading';
import ImageHIWOwnersStepOne from '../../components/image-owners-hiw-step-one';
import ImageHIWOwnersStepTwo from '../../components/image-owners-hiw-step-two';
import ImageHIWOwnersStepThree from '../../components/image-owners-hiw-step-three';

// -----------------------------------------------------------------------------
// STYLES:
// -----------------------------------------------------------------------------
const BgTop = styled.div`
  background-image: linear-gradient(170deg, #FFFFFF, #FFF8F3);
  height: 540px;
`;
// -----------------------------------------------------------------------------
const BgBottom = styled.div`
  background-image: linear-gradient(170deg, #FFF8F3, #FFFFFF);
  height: 3000px;
`;
// -----------------------------------------------------------------------------
const Container = styled.div`
  max-width: 450px;
  margin-top: 125px;
`;
// -----------------------------------------------------------------------------
const Right = styled.div`
  max-width: 100%;
`;
// -----------------------------------------------------------------------------
// COMPONENT:
// -----------------------------------------------------------------------------
const OwnersDesktop = () => (
  <React.Fragment>
    <BgTop>
      <div className="pt4" />
      <Section viewType="desktop" style={{ height: '100%' }}>
        <div className="clearfix">
          <div className="md-col md-col-6 pr1">
            <Container>
              <Headline />
              <div className="pt3" />
              <div className="flex justify-center">
                <CTA label="Join our Beta" />
              </div>
            </Container>
          </div>
          <Right className="sm-hide md-col md-col-6">
            <div className="relative">
              <ImageHeaderBG
                className="absolute"
                style={{
                  pointerEvents: 'none', // img overlaps submit btn
                  width: 600,
                  bottom: -70,
                  left: -80,
                }}
              />
            </div>
          </Right>
        </div>
      </Section>
    </BgTop>

    <Section viewType="desktop" style={{ height: '100%' }}>
      <div className="pt4" />
      <div className="pt4" />
      <Subheadline />
      <div className="pt3" />
      <div className="flex justify-center">
        <CTA label="Join our Beta" />
      </div>
      <ImageSubheading
        className="mx-auto mt3"
        style={{ maxWidth: '500px' }}
      />
      <div className="pt4" />
      <div className="pt2" />
    </Section>

    <BgBottom>
      <Section viewType="desktop" style={{ height: '100%' }}>
        <div className="pt4" />
        <div className="pt4" />
        <HowItWorks.Headline />
        <div className="pt4" />
        <div className="pt3" />
        <div className="flex justify-center">
          <div style={{ width: '300px' }} className="col-4">
            <ImageHIWOwnersStepOne style={{ maxWidth: '300px' }} />
          </div>
          <div className="px3" />
          <div className="col-5 flex flex-column justify-center">
            <HowItWorks.StepOne />
          </div>
        </div>

        <div className="pt3" />
        <div className="flex justify-center">
          <div style={{ width: '300px' }} className="col-4">
            <ImageHIWOwnersStepTwo style={{ maxWidth: '300px' }} />
          </div>
          <div className="px3" />
          <div className="col-5 flex flex-column justify-center">
            <HowItWorks.StepTwo />
          </div>
        </div>

        <div className="pt3" />
        <div className="flex justify-center">
          <div style={{ width: '300px' }} className="col-4">
            <ImageHIWOwnersStepThree style={{ maxWidth: '300px' }} />
          </div>
          <div className="px3" />
          <div className="col-5 flex flex-column justify-center">
            <HowItWorks.StepThree />
            <div className="flex justify-start mt3">
              <CTA label="Start Searching" />
            </div>
          </div>
        </div>
        <div className="pt4">
          <Text size="L" color="secondary" className={'m0 center'}>What our members say</Text>
        </div>
        <OwnerEmily
        style={{ width: '100px' }} 
        className="col-4" 
        text={`“Pet Digs helped us take our honeymoon without any stress. The best part was knowing we had a sitter that was thrilled to be in our house as well as loved our puppy. She sent us updates throughout her stay and I loved the ease of the app. As a couple who travels often, it was great to find a resource like Pet Digs!“ -- Emily`}/>
        <OwnerSuzanne 
        style={{ width: '100px' }} 
        className="col-4" 
        text={`“We've been house swapping for years now, but with two kitties it's always been challenging to ensure the people are staying at our place are really cat lovers and qualified/comfortable taking care of them. Pet Digs is exactly what we've needed: house swapping with qualified cat lovers who have real experience with cats! Using Pet Digs allows us to go on vacation, relax and have a great time knowing that they're well taken care of. We love it!” -- Suzanne`}/>
        <OwnerJulia 
        style={{ width: '100px' }} 
        className="col-4" 
        text={`"We've used local petsitters in the past, and it can get expensive to have someone visit your pets every day for a couple of weeks. Plus, with Pet Digs, we knew our cats were getting some 1-1 time with a loving human. Knowing that our cats weren't lonely while we were traveling definitely gave us peace of mind." -- Julia`}/>
       </Section>
    </BgBottom>
  </React.Fragment>
);

export default OwnersDesktop;
