import React from 'react';
import Img from 'gatsby-image';
import Text from '../text';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const QuoteWithImage = ({ className, style, fluid, text }) => (
  <div className="pt3">
    <div className="flex justify-center">
      <div style={style} className={className}>
      <Img className={className} style={style} fluid={fluid}/>
      </div>
      <div className="px3" />
      <div className="col-5 flex flex-column justify-center">
          <Text size="S" className={'m0 mt1 center'}>{text}</Text>
      </div>
    </div>
  </div>
);
export default QuoteWithImage;
