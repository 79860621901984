import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import QuoteWithImage from '../quote-with-image';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const ownerEmily = ({ className, style, text }) => (
  <StaticQuery
    query={graphql`
    query {
      placeholderImage: file(relativePath: { eq: "owner_emily.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }`}
    render={data => (
      <QuoteWithImage className={className} style={style} text={text} fluid={data.placeholderImage.childImageSharp.fluid}/>
    )}
  />
);
export default ownerEmily;
