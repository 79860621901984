import React from 'react';
import Text from '../../text';

// -----------------------------------------------------------------------------
// COMPONENTS:
// -----------------------------------------------------------------------------
export const Headline = () => (
  <React.Fragment>
    <Text size="L" color="secondary" className="m0 mx-auto center">
      How It Works
    </Text>
    <div className="flex justify-center">
      <Text className="m0 mt1 center" style={{ maxWidth: '820px' }}>
      Our web app is available in the San Francisco Bay Area. 
      <br/>
      During Beta membership is free to connect with verified pet sitters in our community! 
      </Text>
    </div>
  </React.Fragment>
);

export const StepOne = ({ center, ...rest }) => (
  <div {...rest}>
    <Text size="L" color="secondary" className={`m0 ${ center ? 'center' : '' }`}>
      1. We verify & check
    </Text>
    <Text size="S" className={`m0 mt1 ${ center ? 'center' : '' }`}>
    Comprehensive background checks and outside references are required for all sitters before they can pet sit. Peace of mind is everything.
    </Text>
  </div>
);

export const StepTwo = ({ center, ...rest }) => (
  <div {...rest}>
    <Text size="L" color="secondary" className={`m0 ${ center ? 'center' : '' }`}>
      2. Post when you have a trip
    </Text>
    <Text size="S" className={`m0 mt1 ${ center ? 'center' : '' }`}>
    Complete your profile- add all those cute photos of your pet (we know you have a million!) and basic pet care instructions. Post a listing when you have an upcoming trip. 
    </Text>
  </div>
);

export const StepThree = ({ center, ...rest }) => (
  <div {...rest}>
    <Text size="L" color="secondary" className={`m0 ${ center ? 'center' : '' }`}>
      3. Connect with pet sitters
    </Text>
    <Text size="S" className={`m0 mt1 ${ center ? 'center' : '' }`}>
    Invite a sitter that looks good, or wait for available sitters to apply for your listing. Before confirming someone, we recommend interviewing them to make sure they're a good fit and to work out logistics.
    </Text>
  </div>
);
