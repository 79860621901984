import React from 'react';
import Text from '../../text';

// -----------------------------------------------------------------------------
// COMPONENT:
// -----------------------------------------------------------------------------
const Subheadline = () => (
  <React.Fragment>
    <Text size="L" color="secondary" className="m0 center">
      Minimize Stress for
      <br />
      Your Pet While You Travel
    </Text>
    <div className="flex justify-center">
      <Text className="m0 mt1 center" style={{ maxWidth: '520px' }}>
        Pets stay happy and comfortable in their
        own home while you are away!
      </Text>
    </div>
  </React.Fragment>
);

export default Subheadline;
